@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    font-family: 'Noto Sans Thai', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media print {
    body {
        width: 1300px;
    }
    #sidebar {
        display: none;
    }
    .pagebreak {
        page-break-before: auto;
        page-break-inside: avoid;
    } /* page-break-after works, as well */
    .noprint{
        display:none;
    }
    @page {
        size: A4 landscape; /* Force landscape mode */
        margin: 10mm; /* Adjust margins */
    }
    .section {
        page-break-inside: avoid; /* Keep titles and charts together */
        margin-bottom: 20px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

